import React, { useEffect, useState } from "react"
import HoldLogo from "~/vectors/holding-logo.inline.svg"
import GithubLogo from "~/vectors/github.inline.svg"
import LinkedinLogo from "~/vectors/linkedin.inline.svg"

import "./homepage.scss"

const Homepage = () => {
  const [logoClassName, setLogoClassName] = useState("logo")
  const [bigLinksVisible, setBigLinksVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLogoClassName(`logo logo--visible`)
    }, 300)
    setTimeout(() => {
      setBigLinksVisible(true)
      setLogoClassName(`logo logo--visible logo--animate`)
    }, 2100)
  }, [])

  return (
    <div className="homepage">
      <HoldLogo title="Coming very soon..." className={logoClassName} />
      <div
        className={`big-links homepage__big-links u-layout--fbox ${
          bigLinksVisible ? "big-links--visible" : ""
        }`}
      >
        <a
          className="big-links__link homepage__github-link"
          title="Github"
          href="https://github.com/gomezruo"
        >
          <div className="big-links__link-inner">
            <div className="big-links__graphic">
              <GithubLogo />
            </div>
          </div>
        </a>
        <a
          className="big-links__link homepage__linkedin-link"
          title="Linkedin"
          href="https://www.linkedin.com/in/george-richards-web-developer/"
        >
          <div className="big-links__link-inner">
            <div className="big-links__graphic">
              <LinkedinLogo />
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Homepage
